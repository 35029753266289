import { useMemo } from "react";
import toast from "react-hot-toast";

import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useApi } from "shared/lib/hooks";
import { clsxMerge } from "shared/lib/helpers";
import { useGlobalContext } from "@/hooks/use-global-context";
import { CampaignRecommendationCard } from "./campaign-recommendation-card";

export const OnboardingCampaignReview = () => {
  const { glencocoUser } = useGlobalContext();
  const { ats_campaign_id } = glencocoUser || {};

  const atsCampaignFetcher = useMemo(
    () =>
      ats_campaign_id
        ? (api: APII) => api.getCampaignsV3({ campaign_ids: [ats_campaign_id] })
        : null,
    [ats_campaign_id]
  );

  const [{ data: GetAtsCampaignResponse, isLoading }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: atsCampaignFetcher,
    shouldCallAutomatically: true,
    onError: () =>
      toast.error(
        "Failed to retrieve your recommended campaign. Please contact Glencoco."
      ),
  });

  const campaign = useMemo(
    () => GetAtsCampaignResponse?.campaigns?.[0],
    [GetAtsCampaignResponse?.campaigns]
  );

  return !isLoading ? (
    <section className={clsxMerge("flex w-full justify-center")}>
      {campaign && <CampaignRecommendationCard {...campaign} />}
    </section>
  ) : null;
};
