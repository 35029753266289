import { useRef, useCallback, useState, ChangeEventHandler } from "react";

import { CloseIcon } from "shared/ui/icons";
import OnboardingVideoSection from "@/modules/onboarding/onboarding-video-section";
import { clsxMerge } from "shared/lib/helpers";

export const ONBOARDING_INTRO_MODAL_ID = "onboarding-intro-modal";

export const OnboardingIntroModal = ({
  modalId = ONBOARDING_INTRO_MODAL_ID,

  onSuccess = async () => {},
}: {
  modalId?: string;

  onSuccess?: () => any;
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.checked;

    if (value) {
      setIsContentVisible(true);
    } else {
      setTimeout(() => {
        setIsContentVisible(false);
      }, 300);
    }
  };

  const handleOnClick = useCallback(() => {
    const node = checkboxref.current;
    if (node) {
      node?.focus();
      node?.click();
    }
  }, [checkboxref]);

  const handleConfirmation = async () => {
    handleOnClick();
    onSuccess();
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        onChange={handleCheck}
        ref={checkboxref}
      />

      <label htmlFor={modalId} className="modal cursor-pointer">
        <label
          htmlFor=""
          className="flex w-full min-w-[370px] max-w-[1000px] justify-center"
        >
          <div className="modal-box relative flex max-w-[1000px] flex-col rounded-lg p-0">
            <div className="flex items-center justify-between p-6 pb-3">
              <div>
                <h5 className="brand-typography-h3 mb-2">Getting Started</h5>
                <p className="ae-typography-body2">
                  Turn your SDR skills into cash.
                </p>
              </div>
              <div>
                <label htmlFor={modalId} className="cursor-pointer">
                  <CloseIcon className="h-8 w-8 text-black/40" />
                </label>
              </div>
            </div>

            <div className="flex-shrink-1 flex h-[600px] flex-col">
              {isContentVisible && (
                <OnboardingVideoSection
                  onClick={() => {
                    setDisabled(false);
                  }}
                />
              )}

              <div className="flex justify-end p-6">
                <label className="btn-ae-text" htmlFor={modalId}>
                  Cancel
                </label>
                <button
                  className={clsxMerge("btn-ae-default", { disabled })}
                  onClick={handleConfirmation}
                  disabled={disabled}
                >
                  Awesome! What next?
                </button>
              </div>
            </div>
          </div>
        </label>
      </label>
    </>
  );
};
