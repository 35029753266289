import { useRef, useCallback, useState, ChangeEventHandler } from "react";

import { CloseIcon } from "shared/ui/icons";
import { clsxMerge } from "shared/lib/helpers";
import { OnboardingCampaignReview } from "@/components/modules/onboarding/onboarding-campaign-review";

export const ONBOARDING_CAMPAIGNS_MODAL_ID = "onboarding-campaigns-modal";

export const OnboardingCampaignsModal = ({
  modalId = ONBOARDING_CAMPAIGNS_MODAL_ID,

  onSuccess = async () => {},
}: {
  modalId?: string;

  onSuccess?: () => any;
}) => {
  const [disabled] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.checked;

    if (value) {
      setIsContentVisible(true);
    } else {
      setTimeout(() => {
        setIsContentVisible(false);
      }, 300);
    }
  };

  const handleOnClick = useCallback(() => {
    const node = checkboxref.current;
    if (node) {
      node?.focus();
      node?.click();
    }
  }, [checkboxref]);

  const handleConfirmation = async () => {
    await onSuccess();
    handleOnClick();
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        onChange={handleCheck}
        ref={checkboxref}
      />

      <label htmlFor={modalId} className="modal cursor-pointer">
        <label htmlFor="" className="flex justify-center">
          <div className="modal-box relative w-full max-w-[1085px] rounded-lg p-9">
            <div className="mb-10 flex items-center justify-between">
              <div className="mr-10 mt-3">
                <h5 className="mb-2 font-medium typography-header-5-semibold">
                  Review your first campaign
                </h5>
                <p className="ae-typography-body1 font-light">
                  Get familiar with the campaign you'll be auditioning for.
                </p>
              </div>
              <div className="absolute right-6 top-6">
                <label htmlFor={modalId} className="cursor-pointer">
                  <CloseIcon className="h-8 w-8 text-black/40" />
                </label>
              </div>
            </div>

            <div className="min-h-[500px]">
              {isContentVisible && <OnboardingCampaignReview />}
            </div>

            <div
              className={clsxMerge(
                "sticky bottom-0 z-10 mt-7 flex items-center justify-end"
              )}
            >
              <button
                className={clsxMerge(
                  "btn-ae-default btn-ae-l w-[220px] uppercase",
                  {
                    disabled: disabled,
                  }
                )}
                onClick={handleConfirmation}
                disabled={disabled}
              >
                Start Training
              </button>
            </div>
          </div>
        </label>
      </label>
    </>
  );
};
