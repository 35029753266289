import { useEffect, useState } from "react";
import ReactPlayerVimeo from "react-player/vimeo";
import clsx from "clsx";

const OnboardingVideoSection = ({ onClick }: { onClick?: () => void }) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <section onClick={onClick} className="h-full">
      {/* Player ratio: 100 / (1280 / 720) */}
      <div className="relative h-full">
        {isClient && (
          <ReactPlayerVimeo
            className={clsx(
              "absolute left-0 top-0",
              "transition-all duration-1000 animate-in fade-in"
            )}
            url="https://vimeo.com/931216190?share=copy"
            controls
            playbackRate={1.25}
            width="100%"
            height="100%"
            onPlay={onClick}
          />
        )}
      </div>
    </section>
  );
};

export default OnboardingVideoSection;
