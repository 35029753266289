import { FC, useEffect, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { CampaignCard } from "shared/ui";
import { CAMPAIGN_CARD_TYPES } from "shared/ui/cards/campaign-card";

import { CampaignRecommendationCardI } from "./interface";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export const FrontCard: FC<CampaignRecommendationCardI> = ({
  onFlip,
  isFlipped,
  ...props
}) => {
  const { isSelected, recommendation_reason } = props;

  const [isVisible, setIsVisibe] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisibe(true);
    }, 300);
  }, []);

  return (
    <div
      className={clsxMerge(
        "h-full p-0",
        "transition-all duration-200",
        "btn-free",
        "flex flex-col justify-start",
        {
          "opacity-100": isVisible,
          "opacity-0": !isVisible || isFlipped,
          "!bg-[#E8F3FF]": isSelected,
          "shadow-[0_0_0_3px_#4474E3]": isSelected,
          "shadow-[0_0_0_0.5px_#C2C2C2]": !isSelected,
        }
      )}
    >
      <div>
        <CampaignCard
          type={CAMPAIGN_CARD_TYPES.HIGHLIGHTS}
          {...props}
          className={clsxMerge(
            "p-6",
            "transition-all duration-300",
            "bg-transparent",
            "min-h-[366px]"
          )}
        />

        <button
          className="mb-4 flex w-full items-center justify-center"
          onClick={onFlip}
        >
          <ArrowPathIcon className="mr-1 w-5 text-[#4474E3]" />
          <span className="brand-typography-h7 text-[#4474E3]">
            View Details
          </span>
        </button>
      </div>

      <div
        className={clsxMerge(
          "border-t-[0.5px] p-6",
          "transition-all duration-300",
          "relative left-[-1px] top-[1px] w-[calc(100%+2px)]",
          "rounded-b-lg",
          "grow",
          {
            "!bg-[#4474E3] text-white": isSelected,
          }
        )}
      >
        <h6 className="ae-typography-body1 mb-2 font-semibold">
          Recommended For You
        </h6>
        <p className="brand-typography-body4">
          {recommendation_reason
            ? recommendation_reason
            : "Selected by the Glencoco team with love!"}
        </p>
      </div>
    </div>
  );
};
