import { clsxMerge } from "shared/lib/helpers";

const MAX = 2;

export const OnboardingProgressBar = ({
  step: _step,
  className,
}: {
  step?: number;
  className?: string;
}) => {
  const step = _step || 0;

  return (
    <div className={clsxMerge(className)}>
      <span className="brand-typography-body3">
        {step} of {MAX}
      </span>
      <div className="h-6 w-full rounded-2xl bg-white">
        <div
          className={clsxMerge(
            "h-6 rounded-2xl bg-[#F88167]",
            "transition-all duration-300"
          )}
          style={{ width: `${Math.ceil(100 * (step / MAX))}%` }}
        />
      </div>
    </div>
  );
};
