import { ReactNode } from "react";

import { CheckmarkIcon } from "shared/ui/icons";
import { clsxMerge } from "shared/lib/helpers";

export const OnboardingCard = ({
  title,
  subTitle,
  imgSrc,
  className,
  disabled,
  isCompleted,
  onClick,
}: {
  title?: string;
  subTitle?: ReactNode;
  imgSrc?: string;
  className?: string;
  disabled?: boolean;
  isCompleted?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={clsxMerge(
        "flex w-full justify-between gap-4",
        "rounded-lg border-[1px] p-7",
        "bg-white",
        className
      )}
    >
      <div className="flex flex-col justify-between">
        <div>
          <h3 className="b-typography-h3 mb-3">{title}</h3>
          <p className={clsxMerge("b-typography-body3 mb-3", "text-black/70")}>
            {subTitle}
          </p>
        </div>

        <div className="flex items-center gap-3">
          {isCompleted && (
            <button
              className={clsxMerge(
                "btn-ae-default min-w-[140px]",
                "flex items-center",
                {
                  disabled,
                }
              )}
              onClick={onClick}
            >
              <CheckmarkIcon className="w-6 text-white" />{" "}
              <span>Completed</span>
            </button>
          )}

          {!isCompleted && (
            <button
              className={clsxMerge("btn-nofill btn min-w-[140px]", {
                disabled,
              })}
              onClick={onClick}
            >
              Start
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center">
        {imgSrc && (
          <img
            src={imgSrc}
            className={clsxMerge("mr-10 h-[120px] object-contain")}
            alt=""
          />
        )}
      </div>
    </div>
  );
};
