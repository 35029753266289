import {
  FC,
  MouseEventHandler,
  useRef,
  useState,
  MutableRefObject,
} from "react";

import ReactCardFlip from "react-card-flip";

import { CampaignRecommendationCardI } from "./interface";
import { FrontCard } from "./front-card";
import { BackCard } from "./back-card";
import { clsxMerge } from "shared/lib/helpers";

export const CampaignRecommendationCard: FC<CampaignRecommendationCardI> = ({
  ...props
}) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [isFlipped, setIsFlipped] = useState(false);

  const handleOnFlip =
    (flag: boolean): MouseEventHandler<HTMLLIElement> =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setIsFlipped(flag);
    };

  return (
    <div
      onClick={() => setIsFlipped(!isFlipped)}
      ref={ref}
      className={clsxMerge(
        "w-[365px] animate-fadein",
        "[&_.react-card-flip]:h-full [&_.react-card-flip]:[perspective:0px]",
        "[&_.react-card-flipper]:[perspective:1000px]"
      )}
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        containerStyle={{ transformStyle: "preserve-3d" }}
        flipSpeedBackToFront={0.5}
        flipSpeedFrontToBack={0.5}
      >
        <FrontCard
          {...props}
          onFlip={handleOnFlip(true)}
          isFlipped={isFlipped}
        />

        <BackCard
          {...props}
          onFlip={handleOnFlip(false)}
          isFlipped={!isFlipped}
        />
      </ReactCardFlip>
    </div>
  );
};
